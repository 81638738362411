/* Styles de base pour assurer le défilement correct */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Assurez-vous que les conteneurs permettent le défilement */
.scroll-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Style spécifique pour la page de test du thème */
.theme-test-page {
  min-height: 100vh;
  overflow-y: auto;
}

/* Styles existants que vous pourriez avoir */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
